import * as Yup from "yup";

import { transportDeliveryTypeOptions, yesOrNotSelect } from "@constants/selectOptions";
import { postalCodeEuRegex } from "@constants/regex";
import validationIBAN from "@helpers/validationIBAN";

const financing = Yup.object({
	wants: Yup.object({
		label: Yup.string(),
		value: Yup.boolean()
	}).required("Campo obligatorio"),
	discount: Yup.number().when("wants", ([wants], schema) =>
		wants?.value === yesOrNotSelect[0].value
			? schema.required("Campo obligatorio").min(0, "El campo debe ser mayor que 0")
			: schema.notRequired()
	),
	financialEntity: Yup.object({
		label: Yup.string(),
		value: Yup.string()
	}).when("wants", ([wants], schema) =>
		wants?.value === yesOrNotSelect[0].value
			? schema.required("Campo obligatorio")
			: schema.notRequired()
	),
	TIN: Yup.number().when("wants", ([wants], schema) =>
		wants?.value === yesOrNotSelect[0].value
			? schema.isNumberWithLessThanNDecimals(2)
			: schema.notRequired()
	),
	term: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).when("wants", ([wants], schema) =>
		wants?.value === yesOrNotSelect[0].value
			? schema.required("Campo obligatorio")
			: schema.notRequired()
	),
	quota: Yup.number().when("wants", ([wants], schema) =>
		wants?.value === yesOrNotSelect[0].value
			? schema.min(0, "El campo debe ser mayor que 0").isNumberWithLessThanNDecimals(2)
			: schema.notRequired()
	)
});

const delivery = Yup.object({
	type: Yup.object({
		label: Yup.string(),
		value: Yup.string()
	}).required("Campo obligatorio"),
	dealership: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).when("type", ([type], schema) =>
		type?.value === transportDeliveryTypeOptions[0].value
			? schema.required("Campo obligatorio")
			: schema.notRequired()
	),
	useSameAddress: Yup.boolean().when("type", ([type], schema) =>
		type?.value === transportDeliveryTypeOptions[1].value
			? schema.required("Campo obligatorio")
			: schema.notRequired()
	),
	alternativeProvince: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).when(["type", "useSameAddress"], ([type, useSameAddress], schema) =>
		type?.value === transportDeliveryTypeOptions[1].value && !useSameAddress
			? schema.required("Campo obligatorio")
			: schema.notRequired()
	),
	alternativeTown: Yup.string().when(["type", "useSameAddress"], ([type, useSameAddress], schema) =>
		type?.value === transportDeliveryTypeOptions[1].value && !useSameAddress
			? schema.required("Campo obligatorio")
			: schema.notRequired()
	),
	alternativeAddress: Yup.string().when(
		["type", "useSameAddress"],
		([type, useSameAddress], schema) =>
			type?.value === transportDeliveryTypeOptions[1].value && !useSameAddress
				? schema.required("Campo obligatorio")
				: schema.notRequired()
	),
	alternativeCP: Yup.string().when(["type", "useSameAddress"], ([type, useSameAddress], schema) =>
		type?.value === transportDeliveryTypeOptions[1].value && !useSameAddress
			? schema.matches(postalCodeEuRegex, "Código postal inválido")
			: schema.notRequired()
	)
});

const warranty = Yup.object({
	premiumWants: Yup.object({
		label: Yup.string(),
		value: Yup.boolean()
	}).required("Campo obligatorio"),
	premiumAmount: Yup.number().when("premiumWants", ([premiunWants], schema) =>
		premiunWants?.value
			? schema
					.required("Campo obligatorio")
					.min(0, "El campo debe ser mayor que 0")
					.integer("No se admiten decimales")
			: schema.notRequired()
	),
	extraWants: Yup.object({
		label: Yup.string(),
		value: Yup.boolean()
	}).when("premiumWants", ([premiunWants], schema) =>
		premiunWants?.value ? schema.required("Campo obligatorio") : schema.notRequired()
	),
	extraAmount: Yup.number().when(
		["premiumWants", "extraWants"],
		([premiunWants, extraWants], schema) =>
			premiunWants?.value && extraWants?.value
				? schema.required("Campo obligatorio").min(0, "El campo debe ser mayor que 0")
				: schema.notRequired()
	),
	extraIBAN: Yup.string().when(
		["premiumWants", "extraWants"],
		([premiunWants, extraWants], schema) =>
			premiunWants?.value && extraWants?.value
				? schema
						.required("Campo obligatorio")
						.test("isSelectValidation", "IBAN inválido", function (value) {
							const { path, createError } = this;
							if (!validationIBAN(value)) {
								createError({ path, message: this.message });
								return false;
							}
							return true;
						})
				: schema.notRequired()
	)
});

const insurance = Yup.object({
	wants: Yup.object({
		label: Yup.string(),
		value: Yup.boolean()
	}).required("Campo obligatorio"),
	company: Yup.object({
		label: Yup.string(),
		value: Yup.string()
	}).when("wants", ([wants], schema) =>
		wants?.value ? schema.required("Campo obligatorio") : schema.notRequired()
	),
	financing: Yup.object({
		label: Yup.string(),
		value: Yup.boolean()
	}).when("wants", ([wants], schema) =>
		wants?.value
			? schema
					.required("Campo obligatorio")
					.test(
						"checkFinancing",
						"Debes seleccionar “Financia: Sí” en Datos de la venta.",
						function (wants) {
							const { path, createError, from, message } = this;

							const wantsInsuranceFinancing = wants?.value;

							if (
								wantsInsuranceFinancing &&
								from[3].value.sellInfo.financing.wants?.value !== wantsInsuranceFinancing
							) {
								createError({ path, message: message });
								return false;
							}

							return true;
						}
					)
			: schema.notRequired()
	),
	amount: Yup.number().when("wants", ([wants], schema) =>
		wants?.value
			? schema
					.required("Campo obligatorio")
					.min(0, "El campo debe ser mayor que 0")
					.integer("No se admiten decimales")
					.min(1, "Valor minimo: 1 €")
			: schema.notRequired()
	)
});

export const sellContractSellInfoSchema = Yup.object({
	pvp: Yup.number().min(0, "El campo debe ser mayor que 0"),
	ownershipChangeAmount: Yup.number()
		.min(0, "El campo debe ser mayor que 0")
		.integer("No se admiten decimales"),
	gestoria: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obligatorio"),
	financing,
	transport: Yup.object({
		sellType: Yup.object({
			label: Yup.string(),
			value: Yup.string()
		}).required("Campo obligatorio"),
		delivery,
		wants: Yup.object({
			label: Yup.string(),
			value: Yup.boolean()
		}).required("Campo obligatorio"),
		amount: Yup.number().min(0, "El campo debe ser mayor que 0").integer("No se admiten decimales")
	}),
	warranty,
	insurance
});
