import { useState, useEffect, useReducer, useContext } from "react";
import { Label, Table } from "reactstrap";
import { MdClose, MdDone, MdEdit } from "react-icons/md";
import { FaEye } from "react-icons/fa";

import styles from "./index.module.scss";
import Mainlayout from "@layouts/MainLayout";
import { filterReducer, initialEmployeeFilters } from "@views/EmployeeList/filters";
import { UserContext } from "@contexts/UserContext";
import { get } from "@services/axiosCalls";
import { errorToast } from "@helpers/toastFunctions";

import { useTranslation } from "react-i18next";

import Select from "@components/Select";
import MultiSelectChipsList from "@components/MultiSelectChipsList";


const EmployeeList = () => {

  const { t } = useTranslation("employeeList");

  const { user: employeeId } = useContext(UserContext);
  const [employees, setEmployees] = useState([]);
  const [employeesFiltered, setEmployeesFiltered] = useState([]);
  const [employeeFilters, dispatchFilters] = useReducer(
    filterReducer,
    initialEmployeeFilters
  );

  const [employeeTypesOptions, setEmployeeTypesOptions] = useState([]);
  const [localesOptions, setLocalesOptions] = useState([]);
  const employeeStatesOptions = [
    { value: 1, label: "Activo" },
    { value: 0, label: "Inactivo" },
  ];

  const [filterList, setFilterList] = useState([]);

  useEffect(() => {
    const _employeeLocals = employeeFilters.locales.map(x => localesOptions.find(e => e.value === x).label);
    const _employeeStates = employeeFilters.types.map(x => employeeTypesOptions.find(e => e.value === x).label);
    const _filterList = [
      _employeeLocals,
      _employeeStates,
    ];

    const filterListFlat = _filterList.flatMap(list => list);

    setFilterList(filterListFlat);
  }, [employeeFilters]);

  useEffect(() => {
    // Get all the employee types from employees
    get(`/api/employee/${employeeId}/types`)
      .then((res) =>
        setEmployeeTypesOptions(
          res.map((x) => ({
            value: x.id,
            label: x.nombre,
          }))
        )
      )
      .catch((err) => errorToast(err));

    // Get all the employee locals from employees
    get(`/api/employee/${employeeId}/locales`)
      .then((res) =>
        setLocalesOptions(res.map((x) => ({ value: x.id, label: x.nombre })))
      )
      .catch((err) => errorToast(err.response.data.message));
  }, [employeeId]);

  useEffect(() => {
    (async () =>
      get(`/api/employee/list`)
        .then((res) => setEmployees(res))
        .catch((err) => errorToast(err)))();
  }, [employeeId]);

  useEffect(() => {
    // Check every filter condition else return the employee
    setEmployeesFiltered(
      employees.filter((emp) => {
        let condition = true;
        if (employeeFilters.state !== "")
          condition &= emp.activo === employeeFilters.state;
        if (employeeFilters.types.length !== 0)
          condition &= employeeFilters.types.includes(emp.type.id);
        if (employeeFilters.locales.length !== 0)
          condition &= employeeFilters.locales.every((localId) =>
            emp.locales.some((x) => x.id === localId)
          );

        return condition;
      })
    );
  }, [employees, employeeFilters]);

  return (
    <Mainlayout>
      <Mainlayout.Content full contentTitle={t("employeeList")}>
        <div className="col-12 d-flex flex-column align-self-center">
          <div className="mt-3 w-100">
            <a className="btn orange-btn" href="/main/agregar-empleado">
              {t("createNewEmployee")}
            </a>

            <div className="d-flex flex-column flex-lg-row w-100">
              <div className={`col-12 col-lg-4 ${styles.filter}`}>
              <Label>{t("selectEmployee")}</Label>
                <Select
                  isMulti
                  placeholder={t("select")}
                  options={employeeTypesOptions}
                  onChange={(values) =>
                    dispatchFilters({
                      type: "changed_types",
                      nextEmpTypes: values,
                    })
                  }
                />
              </div>
              <div className={`col-12 col-lg-4 ${styles.filter}`}>
              <Label>{t("selectLocals")}</Label>
                <Select
                  isMulti
                  placeholder={t("select")}
                  options={localesOptions}
                  onChange={(values) =>
                    dispatchFilters({
                      type: "changed_locales",
                      nextEmpLocales: values,
                    })
                  }
                />
              </div>
              <div className={`col-12 col-lg-4 ${styles.filter}`}>
              <Label>{t("selectEmployeeState")}</Label>
                <Select
                  isClearable
                 placeholder={t("select")}
                  options={employeeStatesOptions}
                  onChange={(values) =>
                    dispatchFilters({
                      type: "changed_state",
                      nextState: values,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap mt-2">
              <MultiSelectChipsList
                dropdownSelected={filterList}
              />
            </div>
          <div className="d-flex flex-column mt-2">
            <div className="d-flex flex-column flex-lg-row justify-content-between">
              <span className="text-success">
                {t("totalEmployees")}:{" "}
                <span className="text-primary">{employees.length}</span>
              </span>
              {employeesFiltered.length !== 0 ? (
                <span className="text-success">
                  {t("filteredEmployees")}:{" "}
                  <span className="text-primary">
                    {employeesFiltered.length}
                  </span>
                </span>
              ) : (
                <></>
              )}
            </div>

            <div className="mt-2" style={{ overflowX: "auto" }}>
              <Table className={styles.table}>
                <thead>
                  <tr>
                     <th>{t("employeeType")}</th>
                    <th>{t("name")}</th>
                    <th>Email</th>
                    <th>{t("active")}</th>
                    <th>{t("actions")}</th>

                  </tr>
                </thead>
                <tbody>
                  {employeesFiltered.map((emp) => (
                    <tr key={emp.id}>
                      <td>{emp.type.nombre}</td>
                      <td>{emp.nombre_apellidos}</td>
                      <td>{emp.email}</td>
                      <td>
                        {
                          {
                            1: <MdDone size={24} color="#26A69A" />,
                            0: <MdClose size={24} color="ff0000" />,
                          }[emp.activo]
                        }
                      </td>
                      <td>
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <a href={`/main/ver-empleado/${emp.id}`}>
                            <FaEye size={24} color="#039BE5" />
                          </a>
                          <a href={`/main/editar-empleado/${emp.id}`}>
                            <MdEdit size={24} color="#039BE5" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Mainlayout.Content>
    </Mainlayout>
  );
};

export default EmployeeList;
