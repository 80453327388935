import React, { useState, useEffect, useContext, useCallback } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import { errorToast, successToast } from "@helpers/toastFunctions";
import { UserContext } from "@contexts/UserContext";
import { useTranslation } from "react-i18next";
import Select from "@components/Select";
import { getFilterSellLeadStateActions } from "@services/Filtros/filtros";

const ModalChangeState = ({ status, setModal, id_lead, id_cliente }) => {
	const { t } = useTranslation("modalChangeState");
	const [lead, setLead] = useState(null);
	const { user } = useContext(UserContext);

	const getLeadData = useCallback(
		async (id) => {
			const response = await post(API.GET_LEAD_VENTA, {
				agent: user,
				id
			});
			setLead(response[0]);
		},
		[user]
	);

	useEffect(() => {
		if(id_lead){
			getLeadData(id_lead);
		}
	}, [getLeadData, id_lead]);

	const changeState = async (opt) => {
		try {
			if (lead && opt.value != lead.id_estado_lead) {
				await post(API.UPDATE_LEAD_VENTA_STATUS, {
					label: opt.label,
					value: opt.value,
					lead: id_lead,
					client: id_cliente,
					user
				});
				successToast("Estado actualizado correctamente");
				setModal(false);
			}
		} catch (err) {
			errorToast(<span>{t("errors.errorChangeState")}</span>);
		}
	};

	return (
		<Modal
			className="modal-lg"
			centered
			isOpen={status}
			toggle={() => setModal()}
		>
			<ModalHeader>Acciones Sobre el Lead</ModalHeader>
			<ModalBody className="p-4">
				<Select
					placeholder={lead?.nombre_estado_lead}
					loadOptions={getFilterSellLeadStateActions}
					value={{
						label: lead?.nombre_estado_lead,
						value: lead?.id_estado_lead
					}}
					onChange={changeState}
				/>
			</ModalBody>
			<ModalFooter></ModalFooter>
		</Modal>
	);
};

export default ModalChangeState;
