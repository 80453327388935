  const BankOptions = [
    { label: 'Santander', value: 'Santander' },
    { label: 'BBVA', value: 'BBVA' },
    { label: 'Sofinco', value: 'Sofinco' },
    { label: 'Cetelem', value: 'Cetelem' },
    { label: 'Caixabank', value: 'Caixabank' },
    { label: 'Cofidis', value: 'Cofidis' },
    { label: 'Abanca', value: 'Abanca' },
    { label: 'Sabadell', value: 'Sabadell' },
    { label : 'Lendrock ', value: 'Lendrock'}
]

export default BankOptions;