import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { Row, Col, Button } from "reactstrap";

import DatePicker from "@components/DatePicker";
import { UserContext } from "@contexts/UserContext";
import PaginationFooter from "@components/PaginationFooter";
import CasesTable from "@components/CasesTable";
import { API } from "@services/urlConstants";
import errorCall from "@services/errorCall";
import { post } from "@services/axiosCalls";
import Mainlayout from "@layouts/MainLayout";
import ModalFilters from "@components/ModalFilters";
import { FiFilter } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import Select from "@components/Select";
import { getFilterAgents, getFilterAppointmentDealerships, getFilterState } from "@services/Filtros/filtros";


const Casos = () => {

  const { t } = useTranslation("cases");

  const { user, rol } = useContext(UserContext);
  const [cases, setCases] = useState([]);
  const [totalCases, setTotalCases] = useState(0);
  const [paginationMinCases, setPaginationMinCases] = useState(0);
  const [showNumbCases, setShowNumbCases] = useState(8);
  const [filters, setFilters] = useState();
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [modalFilter, setModalFilter] = useState(false);

  const adminRoles = [1, 19];
  const authorized = adminRoles.some((r) => rol.indexOf(r) >= 0);

 

  useEffect(() => {
    const initialCalls = async () => {
      document.title = "Flexicar | Mis Casos";
    };
    initialCalls();
  }, []);

  useEffect(() => {
    setPaginationMinCases(0);
    setShowNumbCases(8);
    changeCaseState(0, 8, filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, dateStart, dateEnd]);

  //Comprobamos los permisos de usuario activar el acceso en STATE

  const changePaginationCases = (min, num) => {
    setPaginationMinCases(min);
    setShowNumbCases(num);
    changeCaseState(min, num, filters);
  };

  const deleteFilters = () => {
    setPaginationMinCases(0);
    setShowNumbCases(8);
    setFilters({
      Estados: [],
      Agentes: [],
      Concesionarios: [],
      freeSearch: "",
    });
    setDateStart();
    setDateEnd();
  };

  const changeCaseState = async (min, num, filters) => {
    const callDataCases = {
      min: min > -1 ? min : paginationMinCases,
      limit: num > -1 ? num : showNumbCases,
      user,
      rol,
      filters,
      dateStart: dateStart ? moment(dateStart).format("YYYY-MM-DD") : null,
      dateEnd: dateEnd ? moment(dateEnd).format("YYYY-MM-DD") : null,
    };
    try {
      const getCases = await post(API.GET_CASES_BY_USER, callDataCases);
      setCases(getCases.data);
      setTotalCases(getCases.count);
    } catch (e) {
      errorCall(e);
    }
  };

  const getData = (filter, value) => {
    setFilters({ ...filters, [filter]: value });
  };
  const filtersBlock = (
    <>
      <Button outline color="danger" block onClick={deleteFilters}>
        {t("delete")}
      </Button>
      <br />
      <Select
        isMulti
        placeholder={t("states")}
        loadOptions={getFilterState}
        value={filters?.Estados}
        onChange={(value) => setFilters(prev => ({ ...prev, "Estados": value }))}
      />
      <br />
      <Select
        isMulti
        placeholder={t("dealers")}
        loadOptions={getFilterAppointmentDealerships}
        value={filters?.Concesionarios}
        onChange={(value) => setFilters(prev => ({ ...prev, "Concesionarios": value }))}
      />
      <br />
      {authorized && (
        <div>
          <Select
            isMulti
            placeholder={t("agents")}
            loadOptions={getFilterAgents}
            value={filters?.Agentes}
            onChange={(value) => setFilters(prev => ({ ...prev, "Agentes": value }))}

          />
          <br />
        </div>
      )}
      <DatePicker date={dateStart} setDate={setDateStart} info={t("from")} />
      <br />
      <DatePicker date={dateEnd} setDate={setDateEnd} info={t("to")}/>
    </>
  );

  return (
    <>
      <Mainlayout>
        <Mainlayout.Sidebar>{filtersBlock}</Mainlayout.Sidebar>
        <Mainlayout.Content contentTitle={t("sellCases")}>
          <div className="text-right pr-5">
            <span className="text-success">{t("cases")}: </span>
            <span className="text-primary">{totalCases}</span>
          </div>
          <Row>
            <Col>
              <div className="d-flex flex-grow-1">
                <input
                  autoComplete="off"
                  placeholder={t("search")}
                  className="form-control ds-input mt-1"
                  type="text"
                  value={filters?.freeSearch}
                  onChange={(e) => getData("freeSearch", e.target.value)}
                />
                <Button
                  outline
                  className="ml-2 d-xl-none d-block"
                  onClick={() => setModalFilter(true)}
                >
                  <FiFilter size={25} />
                </Button>
              </div>
              <br />
              {cases && <CasesTable cases={cases} />}
              <PaginationFooter
                paginationMin={paginationMinCases}
                total={totalCases}
                showNumb={showNumbCases}
                elements={cases}
                changePagination={changePaginationCases}
              />
            </Col>
          </Row>
        </Mainlayout.Content>
      </Mainlayout>

      {modalFilter && (
        <ModalFilters
          footerButtonText="Ver Leads"
          state={modalFilter}
          dispose={() => setModalFilter(false)}
        >
          {filtersBlock}
        </ModalFilters>
      )}
    </>
  );
};

export default Casos;
