
export const DAYLIST = ["Dom.", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];

export const BOOKING_CALENDAR_FILTERS = {
	state_0: "engestion",
	state_1: "pagado",
	state_2: "reservado",
	state_3: "",
	state_4: "cancelado"
};

export function deliveredCalendarFilters(styles) {
	return [
		{ class: styles.bg_green, label: "Entregado" },
		{ class: styles.bg_yellow, label: "Pdte. Entrega" },
		{ class: styles.bg_grey, label: "Pdte. Confirmar" }
	];
}

export function bookingCalendarList(styles) {
    return [
        {class: styles.pagado, label: 'Ganado',},
        /*
        {class: styles.engestion, label: 'En Gestión'},
        */
        {class: styles.reservado, label: 'Reservado'},
        {class: styles.cancelado, label: 'Cancelado'},
    ]
}