import moment from "moment";

export const formatSpanishFullDate = (date) => moment(date).format("DD/MM/YYYY HH:mm");

export const parseDateToISODate = (inputDate) => {
	if (inputDate === undefined || inputDate === null) return null;
	return moment(inputDate).format("YYYY-MM-DD")
};

export const formatDateWithoutHour = (date) => moment(date).format("DD/MM/YYYY");
export const formatDateTimeToISO = (date) => moment(date).format("YYYY-MM-DD HH:mm");
