import { useEffect, useRef, useCallback } from "react";
import { useReactToPrint } from "react-to-print";
import ejsTemplateRender from "@helpers/ejsTemplateRender";

const RenderTemplateEjs = ({ data, templateId, show, setShow }) => {
	const componentRef = useRef(null);

	const reset = () => {
		setShow(false);
		const wrapper = document.getElementById("template");
		if (wrapper) {
			wrapper.innerHTML = ""; // Borra el contenido del elemento
		}
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		onAfterPrint: () => reset()
	});

	const renderTemplate = useCallback(async () => {
		const dataTemplate = data;

		await ejsTemplateRender(templateId, dataTemplate);

		handlePrint();
	}, [data, handlePrint, templateId]);

	useEffect(() => {
		// Llama a renderTemplate solo cuando show sea true
		show && renderTemplate();
	}, [renderTemplate, show]);

	return (
		<div style={{ display: "none" }}>
			<div
				ref={componentRef}
				className="m-5"
				id="template"
			/>
		</div>
	);
};
export default RenderTemplateEjs;
