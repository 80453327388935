import { useFormContext } from "react-hook-form";
import { MdInfoOutline } from "react-icons/md";
import { UncontrolledTooltip } from "reactstrap";

import styles from "@styles/views/SellContract/sell-data.module.scss";

import PaneHeader from "@components/PaneHeader";
import FormInput from "@components/Form/Input/FormInput";
import FormSelect from "@components/Form/Select/FormSelect";
import FormFormattedNumberInput from "@components/Form/FormattedNumberInput/FormFormattedNumberInput";
import FormCheckBox from "@components/Form/Checkbox/FormCheckbox";
import {
	insouranceOptions,
	termOptions,
	transportDeliveryTypeOptions,
	transportSellTypeOptions,
	yesOrNotSelect
} from "@constants/selectOptions";
import BankOptions from "@constants/bankOptions";
import FormIBAN from "@components/Form/IBAN/FormIBAN";

const SellData = ({
	gestoriasOptions,
	dealershipsOptions,
	provincesOptions,
	isDisabledForExtraWarranty
}) => {
	const { watch } = useFormContext();

	const wantsFinancing = watch("sellInfo.financing.wants");

	const wantsTransport = watch("sellInfo.transport.wants");
	const deliveryType = watch("sellInfo.transport.delivery.type");
	const useSameAddress = watch("sellInfo.transport.delivery.useSameAddress");

	const warrantyPremium = watch("sellInfo.warranty.premiumWants");
	const extraWarranty = watch("sellInfo.warranty.extraWants");

	const wantsInsurance = watch("sellInfo.insurance.wants");

	return (
		<div>
			<PaneHeader title="Datos de la venta" />
			<div className={styles.container}>
				<div className={styles.module}>
					<FormFormattedNumberInput
						name="sellInfo.pvp"
						label="PVP Venta"
					/>
					<FormFormattedNumberInput
						name="sellInfo.ownershipChangeAmount"
						label="Importe Cambio de titularidad"
					/>
					<FormSelect
						name="sellInfo.gestoria"
						label="Gestoría"
						options={gestoriasOptions}
					/>
				</div>

				<PaneHeader
					title="Financiación"
					color="secondary"
				/>
				<div className={styles.module}>
					<FormSelect
						name="sellInfo.financing.wants"
						label="Financia"
						options={yesOrNotSelect}
					/>

					{wantsFinancing?.value === yesOrNotSelect[0].value && (
						<>
							<FormFormattedNumberInput
								name="sellInfo.financing.discount"
								label="Descuento financiación"
							/>
							<FormSelect
								name="sellInfo.financing.financialEntity"
								label="Entidad financiera"
								options={BankOptions}
							/>

							<FormFormattedNumberInput
								name="sellInfo.financing.TIN"
								label="TIN"
								suffix=" "
								placeholder=" "
							/>
							<FormSelect
								name="sellInfo.financing.term"
								label="Plazo"
								options={termOptions}
							/>
							<FormFormattedNumberInput
								name="sellInfo.financing.quota"
								label="Cuota"
								suffix=" €/mes"
								placeholder=" "
							/>
						</>
					)}
				</div>

				<PaneHeader
					title="Transporte"
					color="secondary"
				/>
				<div className={styles.module}>
					<FormSelect
						name="sellInfo.transport.sellType"
						label="Tipo de venta"
						options={transportSellTypeOptions}
					/>

					<FormSelect
						name="sellInfo.transport.wants"
						label="Transporte"
						options={yesOrNotSelect}
					/>

					{/* If transport.wants es Si */}
					{wantsTransport?.value === yesOrNotSelect[0].value && (
						<>
							<FormFormattedNumberInput
								name="sellInfo.transport.amount"
								label="Importe de entrega"
							/>
						</>
					)}
				</div>

				<div className={styles.module}>
					<FormSelect
						name="sellInfo.transport.delivery.type"
						label="Forma de entrega"
						options={transportDeliveryTypeOptions}
					/>

					{/* If transport.delivery.type === Tienda */}
					{deliveryType?.value === transportDeliveryTypeOptions[0].value && (
						<FormSelect
							name="sellInfo.transport.delivery.dealership"
							label="Tienda de entrega"
							options={dealershipsOptions}
						/>
					)}

					{/* If transport.delivery.type === Domicilio */}
					{deliveryType?.value === transportDeliveryTypeOptions[1].value && (
						<FormCheckBox
							name="sellInfo.transport.delivery.useSameAddress"
							label="Utilizar dirección de facturación"
						/>
					)}
				</div>

				<div className={styles.module}>
					{/* If transport.delivery.type === Domicilio && If transport.delivery.useSameAddress === false */}
					{deliveryType?.value === transportDeliveryTypeOptions[1].value && !useSameAddress && (
						<div className={styles.alternativeAddress}>
							<FormSelect
								name="sellInfo.transport.delivery.alternativeProvince"
								label="Provincia"
								options={provincesOptions}
							/>
							<FormInput
								name="sellInfo.transport.delivery.alternativeTown"
								label="Población"
							/>
							<FormInput
								name="sellInfo.transport.delivery.alternativeAddress"
								label="Dirección"
							/>
							<FormInput
								name="sellInfo.transport.delivery.alternativeCP"
								label="CP"
							/>
						</div>
					)}
				</div>

				<PaneHeader
					title="Garantía"
					color="secondary"
				/>
				<div className={styles.module}>
					<FormSelect
						name="sellInfo.warranty.premiumWants"
						label="Garantía Premium"
						options={yesOrNotSelect}
					/>

					{/* If sellInfo.warranty.premiumWants es Si */}
					{warrantyPremium?.value === yesOrNotSelect[0].value && (
						<>
							<FormFormattedNumberInput
								name="sellInfo.warranty.premiumAmount"
								label="Importe garantía Premium"
							/>
						</>
					)}
				</div>

				<div className={styles.module}>
					{warrantyPremium?.value === yesOrNotSelect[0].value && (
						<>
							<FormSelect
								name="sellInfo.warranty.extraWants"
								label={
									<div className={styles.extraWarrantyTooltip}>
										Extra Garantía
										<MdInfoOutline
											id="garantia-tooltip"
											className={styles.extraWarrantyTooltipIcon}
											size={22}
										/>
										<UncontrolledTooltip
											target="garantia-tooltip"
											style={{ opacity: 0.8 }}
										>
											La Garantía Extendida amplía la cobertura de la Garantía Premium durante un
											año adicional a través de Caser. No disponible para vehículos a partir de 8
											años o 140.000km.
										</UncontrolledTooltip>
									</div>
								}
								options={yesOrNotSelect}
								isDisabled={isDisabledForExtraWarranty}
							/>

							{/* If sellInfo.warranty.premiumWants es Si && sellInfo.warranty.extraWants es Si*/}
							{extraWarranty?.value === yesOrNotSelect[0].value && (
								<>
									<FormFormattedNumberInput
										name="sellInfo.warranty.extraAmount"
										label="Importe Extra Garantía"
										disabled
									/>
									<FormIBAN
										name="sellInfo.warranty.extraIBAN"
										label="Nº de cuenta"
									/>
								</>
							)}
						</>
					)}
				</div>

				<PaneHeader
					title="Seguro del vehículo"
					color="secondary"
				/>
				<div className={styles.module}>
					<FormSelect
						name="sellInfo.insurance.wants"
						label="Seguro Auto"
						options={yesOrNotSelect}
					/>

					{/* If sellInfo.insurance.wants es Si */}
					{wantsInsurance?.value === yesOrNotSelect[0].value && (
						<>
							<FormSelect
								name="sellInfo.insurance.company"
								label="Compañía"
								options={insouranceOptions}
							/>
							<FormSelect
								name="sellInfo.insurance.financing"
								label="Financia"
								options={yesOrNotSelect}
							/>
							<FormFormattedNumberInput
								name="sellInfo.insurance.amount"
								label="Importe Seguro Auto"
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default SellData;
