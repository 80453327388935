import { useState } from "react";
import {
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  Modal,
} from "reactstrap";

const DatosVehiculoCarousel = ({ photos }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [showInModal, setShowInModal] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === photos.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? photos.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slider = (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators
        items={photos && photos}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {photos?.map((item) => {
        return (
          <CarouselItem
            className="pointer"
            tag="div"
            key={item.url}
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
          >
            <img
              alt="carousel"
              onClick={() => setShowInModal(true)}
              className="d-block w-100"
              src={item.url}
            />
          </CarouselItem>
        );
      })}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );

  return (
    <>
      {slider}

      <Modal
        className="modal-xxl"
        isOpen={showInModal}
        toggle={() => setShowInModal(false)}
      >
        {slider}
      </Modal>
    </>
  );
};

export default DatosVehiculoCarousel;
