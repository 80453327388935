import { useEffect, useState } from "react";

export function useFlatFilterList(multiselects) {

    const [filterList, setFilterList] = useState([]);

    useEffect(() => {
        const _filterList = multiselects;

        const filterListFlat = _filterList.flatMap(list => list.map(item => item.label.toString()));

        setFilterList(filterListFlat);
    }, multiselects);

    return filterList;
}
