import React, { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

/**
 * @typedef {Object} Props
 * @property {React.ReactNode} children - The child elements of the component.
 * @property {string} i18nErrorsFile - The name of the file that has the keys and messages for the schema errors.
 * @property {function} onSubmit - The function called when the form is submitted.
 * @property {function} schema - The schema that will be evaluated for the form validation.
 * @property {string} formValidationMode - The mode for form validation.
 * @property {Object} defaultValues - The default values for form fields.
 * @property {string} className - The CSS class for the form.
 * @property {React.Ref} ref - A React ref that can be used to trigger form-related actions imperatively.
 */

/**
 * @type React.ForwardRefRenderFunction<?, Props>
 */
const Form = forwardRef(function Form(
  {
    children,
    i18nErrorsFile,
    onSubmit,
    schema,
    formValidationMode,
    defaultValues,
    className,
  },
  ref
) {
  const translate = useTranslation(i18nErrorsFile);
  const formRef = useRef(null);

  const resolver = useMemo(() => {
    if (!schema) return null;
    if (!i18nErrorsFile) return yupResolver(schema, { strict: true });
    if (typeof schema !== "function")
      throw new Error(
        "Schema needs to be a function to pass the 't' for the i18n errors"
      );

    return yupResolver(schema(translate));
  }, [i18nErrorsFile, schema, translate]);

  const methods = useForm({
    resolver,
    mode: formValidationMode,
    defaultValues,
  });

  useImperativeHandle(ref, () => ({
    ...methods,
    triggerSubmit: () => {
      if (formRef.current) {
        formRef.current.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
      }
    },
  }));

  return (
    <FormProvider {...methods}>
      <form
        ref={formRef}
        onSubmit={methods.handleSubmit(onSubmit)}
        style={{ width: "100%" }}
        className={className}
      >
        {children}
      </form>
    </FormProvider>
  );
});

export default Form;
