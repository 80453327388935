import { useContext, useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { AiOutlineSend } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import Mainlayout from "@layouts/MainLayout";
import { UserContext } from "@contexts/UserContext";
import { errorToast, successToast } from "@helpers/toastFunctions";
import { changePassword } from "@services/Auth/AuthService";

const ChangePassword = () => {
	const { t } = useTranslation("changePassword");
	const router = useHistory();
	const { setPasswordChangeRequired } = useContext(UserContext);

	const [inputs, setInputs] = useState({
		actualPwd: "",
		newPwd: "",
		newPwdRepeat: ""
	});

	const handleInputChange = (e) => {
		const value = e.target.value;
		setInputs({
			...inputs,
			[e.target.name]: value
		});
	};

	const submitPwdChange = async (e) => {
		e.preventDefault();

		// Check that the inputs are filled
		if (!Object.values(inputs).every((x) => x)) errorToast(t("errors.errorFillAllFields"));
		// Compare that newPwd and newPwdRepeat are the same
		else if (inputs.newPwd !== inputs.newPwdRepeat) errorToast(t("errors.errorPassworNotMatch"));
		else
			try {
				const response = await changePassword(inputs.actualPwd, inputs.newPwd);
				successToast(response.message);
				setPasswordChangeRequired(false);
				router.push("/main/admin");
			} catch (err) {
				if (err.response.status === 400) errorToast(t("errors.infoPassword"));
				else if (err.response.status === 401) errorToast(t("errors.notMatch"));
				else console.error(err);
			}
	};

	return (
		<Mainlayout>
			<Mainlayout.Content
				contentTitle={t("changePassword").toUpperCase()}
				full
			>
				<div className="d-flex flex-column align-items-center">
					<div className="col-12 col-lg-10 col-xl-8 ml-0">
						<Form
							onSubmit={submitPwdChange}
							className={styles.changePasswordForm}
						>
							<FormGroup>
								<Label for="actualPwd">{t("currentPassword")}</Label>
								<Input
									name="actualPwd"
									placeholder={t("currentPassword")}
									type="password"
									value={inputs.actualPwd}
									onChange={handleInputChange}
								/>
							</FormGroup>

							<FormGroup>
								<Label for="newPwd">{t("newPassword")}</Label>
								<Input
									name="newPwd"
									placeholder={t("newPassword")}
									type="password"
									value={inputs.newPwd}
									onChange={handleInputChange}
								/>
							</FormGroup>

							<FormGroup>
								<Label for="newPwdRepeat">{t("repeatPassword")}</Label>
								<Input
									name="newPwdRepeat"
									placeholder={t("repeatPassword")}
									type="password"
									value={inputs.newPwdRepeat}
									onChange={handleInputChange}
								/>
							</FormGroup>

							<Button
								outline
								className={`${styles.buttonPassword} d-flex align-items-center orange-btn`}
							>
								<div className="w-100">
									<span>{t("changePassword")}</span>
									<AiOutlineSend className="ml-3" />
								</div>
							</Button>
						</Form>
					</div>
				</div>
			</Mainlayout.Content>
		</Mainlayout>
	);
};

export default ChangePassword;
