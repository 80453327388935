import GenericModal from "@components/GenericModal";
import { Table, UncontrolledTooltip } from "reactstrap";
import { HiCheck, HiX } from "react-icons/hi";
import styles from "./index.module.scss";
import { formatDateWithoutHour } from "@helpers/formatDate";
import { useMediaQuery } from "@hooks/useMediaQuery";
import { FiUserPlus } from "react-icons/fi";
import LeadTypes from "@constants/LeadTypes";
import { useHistory } from "react-router-dom";

export const ModalClientsTable = ({
	isOpen,
	setIsOpen,
	clientsInfo,
	onAddNewClient,
	onSelectClient,
	leadType
}) => {
	const history = useHistory()
	const isDesktop = useMediaQuery("(min-width: 768px)");
	
	const addClientButtonMobile = (
		<>
			<FiUserPlus
				size={24}
				color="white"
				onClick={onAddNewClient}
				id="addClient"
			/>
			<UncontrolledTooltip
				target={"addClient"}
				autoOpen
			>
				Nuevo Cliente
			</UncontrolledTooltip>
		</>
	);
	const handleOpen = () => {
		setIsOpen(false)
		if(leadType === LeadTypes.COMPRA) {
			history.push("/main/listaleads?section=todos");
		}
		
	}

	return (
		<GenericModal
			isOpen={isOpen}
			setIsOpen={handleOpen}
			modalClassName="modal-xxl"
			bodyContent={
				<div className={styles.modalContainer}>
					<div className={`clearfix mb-3 ${styles.titleContainer}`}>
						<span className="tittle ml-4">Clientes</span>
						<div className="tittle-rigth mr-3">
							{isDesktop ? (
								<button
									className="btn btn-light btn-outline-dark btn-flexicar btn-flexicar-mobile"
									onClick={onAddNewClient}
								>
									Nuevo Cliente
								</button>
							) : (
								addClientButtonMobile
							)}
						</div>
					</div>
					<p className="font-weight-bold">Selecciona tu cliente:</p>
					<div className={styles.tableContainer}>
						<Table
							striped
							responsive
							size="sm"
							className={styles.textTable}
						>
							<thead>
								<tr>
									<th>Nombre del Cliente</th>
									<th>Teléfono</th>
									<th>Email</th>
									{leadType === LeadTypes.VENTA && (
										<>
											<th>Última acción</th>
											<th>Vehiculo</th>
										</>
									)}
									<th>Estado</th>
									<th>ZC</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{clientsInfo.map((dataClient, idx) => (
									<tr key={`clients-${idx}`}>
										<td data-label="Nombre del Cliente">
											<span>{`${dataClient.name} ${dataClient.firstSurname || ""}`}</span>
										</td>
										<td data-label="Teléfono">
											<span>{dataClient.contact.primaryPhone}</span>
										</td>
										<td data-label="Email">
											<span>{dataClient.contact.email}</span>
										</td>
										{leadType === LeadTypes.VENTA && (
											<>
												<td data-label="Fecha Creación Lead">
													<span>
														{dataClient.sellLead?.updatedAt
															? formatDateWithoutHour(dataClient.sellLead?.updatedAt)
															: "-"}
													</span>
												</td>
												<td data-label="Vehiculo">
													<span>{`${dataClient.sellLead?.vehicle?.brand || "-"} ${
														dataClient.sellLead?.vehicle?.model || ""
													} `}</span>
												</td>
											</>
										)}
										<td data-label="Estado">
											{dataClient.sellLead?.statusName || dataClient.purchaseLead?.statusName ? (
												<>
													<span>
														{dataClient.sellLead?.statusName || dataClient.purchaseLead?.statusName}
													</span>
												</>
											) : (
												<span className={styles.grey}>Sin lead</span>
											)}
										</td>
										<td
											className={styles.checkWeb}
											data-label="Zona cliente"
										>
											{dataClient.isWebClient ? (
												<HiCheck
													size={24}
													color="green"
												/>
											) : (
												<HiX
													size={20}
													color="red"
												></HiX>
											)}
										</td>
										<td>
											<button
												className="btn btn-light btn-outline-dark btn-flexicar btn-flexicar-mobile"
												onClick={() => onSelectClient(dataClient.id, dataClient.sellLead || dataClient.purchaseLead)}
											>
												Seleccionar
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</div>
				</div>
			}
		/>
	);
};
