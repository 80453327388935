import styles from "@styles/views/SellContract/observations.module.scss";

import PaneHeader from "@components/PaneHeader";
import FormTextArea from "@components/Form/Textarea/FormTextarea";

const Observations = () => {
	return (
		<div>
			<PaneHeader title="Observaciones" />

			<div className={styles.observations}>
				<FormTextArea
					name="observations"
					label="Introduce notas o comentarios en el contrato"
				/>
			</div>
		</div>
	);
};

export default Observations;
