import { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
	Row,
	Col,
	Label,
	Input,
	UncontrolledPopover,
	PopoverHeader,
	PopoverBody
} from "reactstrap";

import styles from "./index.module.scss";
import { UserContext } from "@contexts/UserContext";
import RolType from "@constants/roles";
import thousandsFormat from "@helpers/thousandsFormat";
import { MdHelp } from "react-icons/md";

const VehicleValidationSales = ({
	vehiculo,
	setVehiculo,
	handleSimpleInput,
	handleNumberThousands
}) => {
	const { t } = useTranslation("vehicleValidation");
	const { rol, userType } = useContext(UserContext);

	const disabledEditPrice = !(rol.some((rol) => rol === RolType.TRADING_MANAGER) || userType === 1);

	return (
		<>
			<Row className={styles.centerRow}>
				<Col
					xs="12"
					sm="12"
				>
					<h6 className={styles.bold}>{t("particular")}</h6>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("cashPrice")}*</Label>
					<Input
						placeholder={t("cashPrice")}
						className="form-control ds-input mt-1"
						disabled={disabledEditPrice}
						type="text"
						value={
							typeof vehiculo.pvp_contado === "number"
								? thousandsFormat(vehiculo.pvp_contado)
								: vehiculo.pvp_contado
						}
						onChange={(e) => {
							handleNumberThousands(e, "pvp_contado");
						}}
					/>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>
						{t("financedPrice")}*{" "}
						{vehiculo?.oferta_particulares ? (
							<small style={{ fontStyle: "italic", color: "#ff5d0e" }}> *precio tachado web</small>
						) : null}
					</Label>
					<Input
						placeholder={t("financedPrice")}
						className="form-control ds-input mt-1"
						type="text"
						value={
							typeof vehiculo.pvp_financiado === "number"
								? thousandsFormat(vehiculo.pvp_financiado)
								: vehiculo.pvp_financiado
						}
						disabled={disabledEditPrice}
						onChange={(e) => {
							handleNumberThousands(e, "pvp_financiado");
						}}
					/>
				</Col>

				<Col
					xs="12"
					sm="4"
				>
					<Label
						style={{
							cursor: "pointer",
							marginLeft: "30px",
							marginTop: "20px"
						}}
						check
					>
						<Input
							type="checkbox"
							disabled={disabledEditPrice}
							onChange={() => {
								setVehiculo({
									...vehiculo,
									oferta_particulares: vehiculo.oferta_particulares ? false : true,
									precio_oferta_particulares: vehiculo.oferta_particulares
										? null
										: vehiculo.precio_oferta_particulares
								});
							}}
							checked={vehiculo.oferta_particulares}
						/>
						{t("offer")}
					</Label>
				</Col>
				{vehiculo?.oferta_particulares ? (
					<Col
						xs="12"
						sm="4"
					>
						<Label className={"m-0"}>{t("offer")}</Label>
						<Input
							placeholder={t("offer")}
							className="form-control ds-input mt-1"
							type="text"
							value={
								typeof vehiculo.precio_oferta_particulares === "number"
									? thousandsFormat(vehiculo.precio_oferta_particulares)
									: vehiculo.precio_oferta_particulares
							}
							disabled={disabledEditPrice}
							onChange={(e) => {
								handleNumberThousands(e, "precio_oferta_particulares");
							}}
						/>
					</Col>
				) : null}
				<Col
					xs="12"
					sm="12"
				>
					<h6 className={styles.bold}>{t("financing")}</h6>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("minimumInterestRate")}</Label>
					<Input
						placeholder={"Tipo interes minimo"}
						className="form-control ds-input mt-1"
						type="number"
						value={vehiculo.interes_min}
						disabled={true}
						onChange={(e) => {
							handleSimpleInput(e, "interes_min");
						}}
					/>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("minimumMonths")}</Label>
					<Input
						placeholder={t("minimumMonths")}
						className="form-control ds-input mt-1"
						type="number"
						value={vehiculo.meses_min}
						disabled={true}
						onChange={(e) => {
							handleSimpleInput(e, "meses_min");
						}}
					/>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("maximumInitialContribution")}</Label>
					<Input
						placeholder={t("maximumInitialContribution")}
						className="form-control ds-input mt-1"
						type="number"
						value={vehiculo.aportacion_maxi}
						disabled={true}
						onChange={(e) => {
							handleSimpleInput(e, "aportacion_max");
						}}
					/>
				</Col>

				<Col
					xs="12"
					sm="12"
				>
					<h6 className={styles.bold}>{t("supplies")}</h6>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("transportAmount")}</Label>
					<Input
						placeholder={"Importe transporte"}
						className="form-control ds-input mt-1"
						type="number"
						value={vehiculo.importe_transporte}
						disabled={true}
						onChange={(e) => {
							handleSimpleInput(e, "importe_transporte");
						}}
					/>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("flexicarWarrantyAmount")}</Label>
					<Input
						placeholder={"Importe Garantía Flexicar"}
						className="form-control ds-input mt-1"
						type="number"
						value={vehiculo.importe_garantia}
						disabled={true}
						onChange={(e) => {
							handleSimpleInput(e, "importe_garantia");
						}}
					/>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("transferAmount")}</Label>
					<Input
						placeholder={"Importe trasnferencia"}
						className="form-control ds-input mt-1"
						type="number"
						value={vehiculo.importe_trasnferencia}
						disabled={true}
						onChange={(e) => {
							handleSimpleInput(e, "importe_transferencia");
						}}
					/>
				</Col>
				<Col
					xs="12"
					sm="12"
				>
					<h6 className={styles.bold}>Precios de Operativa</h6>
				</Col>

				<Col
					xs="12"
					sm="4"
				>
					<Label className={"m-0"}>{t("purchasePrice")}</Label>
					<Input
						placeholder={t("purchasePrice")}
						className="form-control ds-input mt-1"
						type="number"
						value={vehiculo.precio_compra}
						disabled={true}
						onChange={(e) => {
							handleSimpleInput(e, "precio_compra");
						}}
					/>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label
						style={{ cursor: "pointer", marginLeft: "30px" }}
						check
					>
						<Input
							type="checkbox"
							onChange={() => {
								setVehiculo({
									...vehiculo,
									libre_cargas: vehiculo.libre_cargas ? false : true
								});
							}}
							checked={vehiculo.libre_cargas}
						/>
						{t("lienFree")}
					</Label>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label
						style={{ cursor: "pointer", marginLeft: "30px" }}
						check
					>
						<Input
							type="checkbox"
							onChange={() => {
								setVehiculo({
									...vehiculo,
									iva_deducible: vehiculo.iva_deducible ? false : true
								});
							}}
							checked={vehiculo.iva_deducible}
						/>
						{t("deductibleVAT")}
					</Label>
				</Col>

				<Col
					xs="12"
					sm="12"
				>
					<h6 className={styles.bold}> {t("operationType")}</h6>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label
						style={{
							cursor: "pointer",
							marginLeft: "30px",
							marginTop: "20px"
						}}
						check
					>
						<Input
							type="checkbox"
							onChange={() => {
								setVehiculo({
									...vehiculo,
									compraventas: vehiculo.compraventas ? false : true
								});
							}}
							checked={vehiculo.compraventas}
						/>
						<h5>{t("carDealers")}</h5>
					</Label>
					<MdHelp
						id="PopoverFocus"
						className="md-36 m-1"
					/>
					<UncontrolledPopover
						placement="bottom"
						target="PopoverFocus"
						trigger="hover"
					>
						<PopoverHeader>{t("carDealers")}</PopoverHeader>
						<PopoverBody>{t("carDealersText")}</PopoverBody>
					</UncontrolledPopover>
				</Col>
				<Col
					xs="12"
					sm="4"
				>
					<Label
						style={{
							cursor: "pointer",
							marginLeft: "30px",
							marginTop: "20px"
						}}
						check
					>
						<Input
							type="checkbox"
							onChange={() => {
								setVehiculo({
									...vehiculo,
									gestion_venta: vehiculo.gestion_venta ? false : true
								});
							}}
							checked={vehiculo.gestion_venta || vehiculo.tipo_contrato === 4}
							disabled={true}
						/>
						<h5>{t("salesManagement")}</h5>
					</Label>
				</Col>

				{vehiculo?.compraventas ? (
					<>
						<Col
							xs="12"
							sm="12"
						>
							<h6 className={styles.bold}>{t("carDealers")}</h6>
						</Col>

						<Col
							xs="12"
							sm="4"
						>
							<Label className={"m-0"}>PVD</Label>
							<Input
								placeholder={"PVD Contado"}
								className="form-control ds-input mt-1"
								type="text"
								value={
									typeof vehiculo.pvd === "number" ? thousandsFormat(vehiculo.pvd) : vehiculo.pvd
								}
								onChange={(e) => {
									handleNumberThousands(e, "pvd");
								}}
							/>
						</Col>

						<Col
							xs="12"
							sm="4"
						>
							<Label
								style={{
									cursor: "pointer",
									marginLeft: "30px",
									marginTop: "20px"
								}}
								check
							>
								<Input
									type="checkbox"
									onChange={() => {
										setVehiculo({
											...vehiculo,
											oferta_compraventas: vehiculo.oferta_compraventas ? false : true
										});
									}}
									checked={vehiculo.oferta_compraventas}
								/>
								{t("offer")}
							</Label>
						</Col>
						{vehiculo?.oferta_compraventas ? (
							<Col
								xs="12"
								sm="4"
							>
								<Label className={"m-0"}>Precio</Label>
								<Input
									placeholder={"Precio"}
									className="form-control ds-input mt-1"
									type="text"
									value={
										typeof vehiculo.precio_oferta_compraventas === "number"
											? thousandsFormat(vehiculo.precio_oferta_compraventas)
											: vehiculo.precio_oferta_compraventas
									}
									onChange={(e) => {
										handleNumberThousands(e, "precio_oferta_compraventas");
									}}
								/>
							</Col>
						) : null}
					</>
				) : null}
				{vehiculo?.gestion_venta ? (
					<>
						<Col
							xs="12"
							sm="12"
						>
							<h6 className={styles.bold}>Gestion de venta</h6>
						</Col>

						<Col
							xs="12"
							sm="4"
						>
							<Label className={"m-0"}>Precio estimado de venta</Label>
							<Input
								placeholder={"Precio estimado venta"}
								className="form-control ds-input mt-1"
								type="text"
								value={
									typeof vehiculo.precio_estimado === "number"
										? thousandsFormat(vehiculo.precio_estimado)
										: vehiculo.precio_estimado
								}
								onChange={(e) => {
									handleNumberThousands(e, "precio_estimado");
								}}
							/>
						</Col>
						<Col
							xs="12"
							sm="4"
						>
							<Label className={"m-0"}>Comision en caso de venta</Label>
							<Input
								placeholder={"Gastos y comisiones"}
								className="form-control ds-input mt-1"
								type="text"
								value={
									typeof vehiculo.comision_venta === "number"
										? thousandsFormat(vehiculo.comision_venta)
										: vehiculo.comision_venta
								}
								onChange={(e) => {
									handleNumberThousands(e, "comision_venta");
								}}
							/>
						</Col>

						<Col
							xs="12"
							sm="4"
						>
							<Label className={"m-0"}>Penalización</Label>
							<Input
								placeholder={"Penalización"}
								className="form-control ds-input mt-1"
								type="text"
								value={
									typeof vehiculo.penalizacion === "number"
										? thousandsFormat(vehiculo.penalizacion)
										: vehiculo.penalizacion
								}
								onChange={(e) => {
									handleNumberThousands(e, "penalizacion");
								}}
							/>
						</Col>
					</>
				) : null}
				<Col
					xs="12"
					sm="12"
				>
					<br />
				</Col>
			</Row>
		</>
	);
};

export default VehicleValidationSales;
