import * as Yup from "yup";

import {
	EMAIL_REGEX,
	ISO_DATE_REGEX,
	ISO_DATE_TIME_REGEX,
	PHONE_REGEX_ES,
	PHONE_REGEX_PT
} from "@constants/regex";
import validationCIF from "@helpers/validationCIF";
import validationDNI from "@helpers/validationDNI";
import validationNIE from "@helpers/validationNIE";
import Locale from "@constants/Locale";

/**
 * @typedef {{
 *   schemaType: import('yup').AnySchema,
 *   name: string,
 *   fn: (...args: any[]) => any
 * }} CustomValidationMethodValues
 */

/**
 * @satisfies {Object<string, CustomValidationMethodValues>}
 */
const customValidationMethods = {
	email: {
		schemaType: Yup.string,
		name: "email",
		fn: function (excludeEmptyString = false, errorMessage = "Email no válido") {
			return this.matches(EMAIL_REGEX, {
				message: errorMessage,
				excludeEmptyString
			});
		}
	},
	ISODate: {
		schemaType: Yup.string,
		name: "isISODateString",
		fn: function (excludeEmptyString = false, errorMessage = "Fecha inválida") {
			return this.matches(ISO_DATE_REGEX, {
				message: errorMessage,
				excludeEmptyString
			});
		}
	},
	ISODateTime: {
		schemaType: Yup.string,
		name: "isISODateTimeString",
		fn: function (errorMessage = "Fecha inválida") {
			return this.matches(ISO_DATE_TIME_REGEX, {
				message: errorMessage
			});
		}
	},
	nDecimals: {
		schemaType: Yup.number,
		name: "isNumberWithLessThanNDecimals",
		fn: function (
			maxDecimals,
			errorMessage = `El campo no puede tener más de ${maxDecimals} decimales`
		) {
			return this.test("numberWithLessThanNDecimalsValidation", errorMessage, function (value) {
				const { path, createError } = this;
				if (value === undefined || value === null)
					return createError({ path, message: "Campo obligatorio" });

				const decimalCount = (value.toString().split(".")[1] || "").length;
				const lessOrEqual = decimalCount <= maxDecimals;

				!lessOrEqual && createError({ path, message: errorMessage });

				return lessOrEqual;
			});
		}
	},
	identification: {
		schemaType: Yup.string,
		name: "identification",
		fn: function (errorMessage = "Identificación inválida") {
			return this.test("identification", errorMessage, function (value) {
				const { path, createError } = this;
				if (value === undefined || value === null)
					return createError({ path, message: "Campo obligatorio" });

				const isValid = validationCIF(value) || validationDNI(value) || validationNIE(value);
				!isValid && createError({ path, message: errorMessage });
				return isValid;
			});
		}
	},
	phone: {
		schemaType: Yup.string,
		name: "phone",
		fn: function (locale = Locale.ES, errorMessage = "Teléfono inválido") {
			const regex = {
				[Locale.ES]: PHONE_REGEX_ES,
				[Locale.PT]: PHONE_REGEX_PT
			}[locale];

			return this.matches(regex, {
				message: errorMessage
			});
		}
	}
};

/**
 * @param {Array<keyof typeof customValidationMethods>} methodIdentifiers
 */
export const loadCustomMethods = (methodIdentifiers) =>
	methodIdentifiers.forEach((identifier) => {
		const customMethod = customValidationMethods[identifier];

		if (customMethod) Yup.addMethod(customMethod.schemaType, customMethod.name, customMethod.fn);
		else throw new Error(`Invalid method identifier: ${identifier}`);
	});

loadCustomMethods(Object.keys(customValidationMethods));
